import { Loader } from "@googlemaps/js-api-loader"

import Slideshow from '../components/slideshow.js';

export default class Itinerary {
  constructor(pageEl, googleMapsOptions) {
    this.pageEl = pageEl;
    this.googleMapsOptions = googleMapsOptions;

    this.map = undefined;
    this.itineraryData = undefined;

    this.converter = new showdown.Converter();

    const urlParams = new URLSearchParams(window.location.search);
    this.itineraryId = urlParams.get('id');

    const loader = new Loader({
      ...googleMapsOptions
    });

    fetch(window.ENV.API_BASE_PATH +'/itineraries/'+this.itineraryId+'?populate=*&locale='+ window.language)
      .then(response => response.json())
      .then(data => {
        this.itineraryData = data.data;
        this._addItineraryInfo();

        loader.load().then(async () => this._initMap() );
      })
      .catch(error => console.error('Erro:', error));


    this.pageEl.querySelector('.point-info__container').addEventListener('click', () => this._closePointInfo());
    this.pageEl.querySelector('.point-info__close-icon').addEventListener('click', () => this._closePointInfo());

    this.pageEl.querySelector('.point-info__content').addEventListener('click', e => e.stopPropagation());
  }

  _addItineraryInfo() {
    if(this.itineraryData.attributes.color) {
      document.querySelectorAll('.bg-color--itinerary').forEach(el => el.classList.remove('bg-color--itinerary'));
      document.querySelectorAll('.bg-color--itinerary-light').forEach(el => el.classList.remove('bg-color--itinerary-light'));
    }

    document.querySelector('[data-color]').style.backgroundColor = this.itineraryData.attributes.color;
    document.querySelector('[data-color]').style.color = this.itineraryData.attributes.text_color == 'dark' ? 'black' : 'white';

    if(this.itineraryData.attributes.text_color == 'dark')
      document.querySelector('[data-color] .heading__back-home').classList.add('heading__back-home--dark');
    else
      document.querySelector('[data-color] .heading__back-home').classList.add('heading__back-home--light');

    document.querySelector('[data-title]').innerHTML = this.itineraryData.attributes.name;

    document.querySelector('[data-info-link]').setAttribute('href', '/info/?id='+this.itineraryData.id);
  }

  async _initMap () {
    const { Map } = await google.maps.importLibrary('maps');
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary('marker');

    let centerPoint, firstPoint;

    if(this.itineraryData.attributes.points.data.length > 0) {
      firstPoint = this.itineraryData.attributes.points.data[0]; //temporary TODO add center point to Strapi
      centerPoint = (this.itineraryData.attributes.center_latitude && this.itineraryData.attributes.center_longitude)
        ? { lat: parseFloat(this.itineraryData.attributes.center_latitude), lng: parseFloat(this.itineraryData.attributes.center_longitude) }
        : { lat: parseFloat(firstPoint.attributes.latitude), lng: parseFloat(firstPoint.attributes.longitude) };
    }

    this.map = new Map(this.pageEl.querySelector('#roteiros-coimbra-map'), {
      center: centerPoint ? centerPoint : { lat: 40.1970713, lng: -8.4082688 },
      zoom: this.itineraryData.attributes.center_zoom || 16,
      fullscreenControl: false,
      mapId: '49e91c4bce7159b8',
      styles: [{"featureType":"all","elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"featureType":"all","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"lightness":17},{"weight":1.2}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#ededed"},{"lightness":20}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"simplified"},{"color":"#c7c7c7"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#e3e3e3"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#d4e6c4"},{"lightness":21}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#bde1f4"},{"lightness":17}]},{"featureType":"water","elementType":"labels.text","stylers":[{"color":"#7da1b6"}]}]
    });

    const itineraryCoordinates = this.itineraryData.attributes.points.data.map(point => {
      return { lat: parseFloat(point.attributes.latitude), lng: parseFloat(point.attributes.longitude), id: point.id };
    });

    const itineraryPath = new google.maps.Polyline({
      path: itineraryCoordinates,
      geodesic: true,
      strokeColor: this.itineraryData.attributes.color || '#ab3388',
      strokeOpacity: 1.0,
      strokeWeight: 3,
    });
    itineraryPath.setMap(this.map);

    itineraryCoordinates.forEach((point, index) => {
      this._addMarker(point, index, this.itineraryData.attributes.color, AdvancedMarkerElement, PinElement);
    });
  }

  _addMarker(point, index, backgroundColor, AdvancedMarkerElement, PinElement) {
    const pinElement = new PinElement({
      glyph: `${index + 1}`,
      glyphColor: 'white',
      background: backgroundColor || '#ab3388',
      borderColor: 'white',
      scale: 1.1,
    });

    const marker = new AdvancedMarkerElement({
      position: { lat: point.lat, lng: point.lng },
      map: this.map,
      content: pinElement.element
    });
    marker.addListener('click', () => this._openPointInfo(index, point.id));
  }

  _openPointInfo(index, id) {
    this.pageEl.querySelector('.point-info').classList.add('point-info--open');
    this._getPointInfo(index, id);
  }

  _closePointInfo() {
    this.pageEl.querySelector('.point-info').scrollTo({
      top: 0,
      behavior: "smooth"
    });
    this.pageEl.querySelector('.point-info').classList.remove('point-info--open');
    this.pageEl.querySelector('.point-info').scrollTo(0,0);
  }

  _getPointInfo(index, id) {
    fetch(window.ENV.API_BASE_PATH +'/points/'+id+'?populate=*&locale='+ window.language)
      .then(response => response.json())
      .then(data => {
        this._addPointInfo(index, data.data);
      })
      .catch(error => console.error('Erro:', error));

  }

  _addPointInfo(index, pointData) {

    this.pageEl.querySelector('[data-point-content]').innerHTML =
      `<h2>
        <span class="point-info__num">
          <svg viewBox="0 0 24 24"><defs><style>.cls-1{fill:none;}.cls-2{fill:${this.itineraryData.attributes.color || '#ab3388'};}.cls-3{font-size:7px;fill:#fff;font-family:OpenSansRoman-Bold, Open Sans;font-weight:700;}</style></defs><path class="cls-1" d="M0,0H24V24H0Z"/><path class="cls-2" d="M12,2A7,7,0,0,0,5,9c0,5.25,7,13,7,13s7-7.75,7-13A7,7,0,0,0,12,2Z"/><text class="cls-3"  x="12" y="12" text-anchor="middle">${index + 1}</text></svg></span>
          <span>${pointData.attributes.name}</span>
      </h2>
      ${pointData.attributes.type ? `<h5>${pointData.attributes.type}</h5>`: ''}
      <ul class="point-info__details">
        ${pointData.attributes.maps_link ?
           `<a class="point-info__detail-item" href="${pointData.attributes.maps_link}" target="_blank">
              <i class="fas fa-map"></i>Google Maps
            </a>` : ''}
        ${pointData.attributes.link ?
           `<a class="point-info__detail-item" href="${pointData.attributes.link}" target="_blank">
              <i class="fas fa-link"></i>Website
            </a>` : ''}
      </ul>
      <div data-point-description="data-point-description">
        ${this.converter.makeHtml(pointData.attributes.description)}
      </div>
      <div class="point-info__paths">
        <h4>Roteiros</h4>
        ${pointData.attributes.itineraries.data.reduce((acc, val) => {
          return acc +
            `<a class="point-info__path" style="background-color: ${val.attributes.color || '#ab3388'};" href="/roteiro/?id=${val.id}">
              <span style="color: ${val.attributes.text_color === 'dark' ? 'black' : 'white'};">${val.attributes.name}</span>
            </a>`;
        }, '')}
      </div>

      ${pointData.attributes.images.data ? (
        pointData.attributes.images.data?.length == 1 ? this._getImageHtml(pointData.attributes.images.data[0]) : this._getSlideshowHtml(pointData.attributes.images.data)
      ) : ''}`;

    if(pointData.attributes.images.data?.length == 1)
      this._initPhotoSwipe(this.pageEl.querySelector('[data-point-content] [data-pswp-src]'));
    else if(pointData.attributes.images.data?.length > 0)
      new Slideshow(this.pageEl.querySelector('[data-point-content] .ma-slideshow'));
  }

  _getImageHtml(imageData) { //TODO add this code to Utils to avoid repetition
    return `<div class="fullwidth-image">
              <a data-pswp-width="${imageData.attributes.width}" data-pswp-height="${imageData.attributes.height}" data-pswp-src="${window.ENV.API_ASSETS_BASE_PATH + imageData.attributes.url}">
                <img class="lazyload" data-srcset="${this._getSrcsetImages(imageData.attributes)}" data-sizes="auto" width="${imageData.attributes.width}" height="${imageData.attributes.height}" src="${window.ENV.API_ASSETS_BASE_PATH + imageData.attributes.formats.small.url}"/>
              </a>
            </div>`;
  }

  _getSlideshowHtml(imagesData) {
    return `<div class="ma-slideshow" id="ma-slideshow-1">
              <div class="glide" data-glide-slideshow="data-glide-slideshow">
                <div class="glide__track" data-glide-el="track">
                  <ul class="glide__slides">
                  ${imagesData.reduce((acc, val) => acc + (
                    `<li class="glide__slide">
                      <a data-pswp-width="${val.attributes.width}" data-pswp-height="${val.attributes.height}" data-pswp-src="${window.ENV.API_ASSETS_BASE_PATH + val.attributes.url}">
                        <img class="lazyload" data-srcset="${this._getSrcsetImages(val.attributes)}" data-sizes="auto" width="${val.attributes.width}" height="${val.attributes.height}" src="${window.ENV.API_ASSETS_BASE_PATH + val.attributes.formats.small.url}"/>
                      </a>
                    </li>`
                  ), '')}
                  </ul>
                </div>
                <div class="ma-slideshow__controls glide__arrows" data-glide-el="controls">
                  <button class="glide__arrow glide__arrow--prev" data-glide-dir="&lt;">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 18.8 30.3" style="enable-background:new 0 0 18.8 30.3;" xml:space="preserve">
                      <polygon fill="#2A2827" points="16.3,30.3 18.8,27.5 5.4,15.1 18.8,2.7 16.3,0 0,15.1"></polygon>
                    </svg>
                  </button>
                  <button class="glide__arrow glide__arrow--next" data-glide-dir="&gt;">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 18.8 30.3" style="enable-background:new 0 0 18.8 30.3;" xml:space="preserve">
                      <polygon fill="#2A2827" points="2.5,0 0,2.8 13.4,15.2 0,27.6 2.5,30.3 18.8,15.2"></polygon>
                    </svg>
                  </button>
                </div>
              </div>
            </div>`;
  }

  _getSrcsetImages(imagesData) {
    let srcset = '';

    if(imagesData.formats.small)
      srcset += `${window.ENV.API_ASSETS_BASE_PATH + imagesData.formats.small.url} ${imagesData.formats.small.width}w, `;
    if(imagesData.formats.medium)
      srcset += `${window.ENV.API_ASSETS_BASE_PATH + imagesData.formats.medium.url} ${imagesData.formats.medium.width}w, `;
    if(imagesData.formats.large)
      srcset += `${window.ENV.API_ASSETS_BASE_PATH + imagesData.formats.large.url} ${imagesData.formats.large.width}w, `;

    srcset += `${window.ENV.API_ASSETS_BASE_PATH + imagesData.url} ${imagesData.width}w`;

    return srcset;
  }

  _initPhotoSwipe(el) {//TODO add this code to Utils to avoid repetition - componente igual
    const pswpElement = document.querySelectorAll('.pswp')[0];

    const options = {
      index: 0,
      mainClass : 'pswp--minimal--dark',
      barsSize : {top:10,bottom:10},
      captionEl : true,
      fullscreenEl : false,
      shareEl : false,
      bgOpacity : 0.65,
      tapToClose : true,
      tapToToggleControls : false,
      history: false
    };

    el.addEventListener('click', (e) => {
      e.preventDefault();

      //if is dragging, don't open photoswipe
      if(el.getAttribute('draggable') == 'false')
        return;

      const pswpItems = [];

      options.index = 1;

      const item = {
        src : el.getAttribute('data-pswp-src'),
        w : el.getAttribute('data-pswp-width'),
        h : el.getAttribute('data-pswp-height')
      };

      pswpItems.push(item);

      const gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, pswpItems, options);
      gallery.init();
    });
  }
}
